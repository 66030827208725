export default {
    getRandomColor() {
        var letters = '0123456789ABCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.round(Math.random() * 15)];
        }
        return color;
    },

    renderLocalPicture(path) {
        return  require('@' + '/assets/' + path);
    },

    renderHeroPicture(id){
        return require("@/item-editor/assets/heroes/id"+id+".png");
    },

    groupBy  (xs, key1, key2) {

        if (key1 && key2) {
            return xs.reduce(function (rv, x) {
                (rv[x[key1][key2]] = rv[x[key1][key2]] || []).push(x);
                return rv;
            }, {});
        }else {
            return xs.reduce(function (rv, x) {
                (rv[x[key1]] = rv[x[key1]] || []).push(x);
                return rv;
            }, {});
        }

    }
}
