let results = [
    {
        "codename": "aqua big attack"
    },
    {
        "codename": "big ice dragon"
    },
    {
        "codename": "blizzard"
    },
    {
        "codename": "blood aura"
    },
    {
        "codename": "blood drop"
    },
    {
        "codename": "blue mark"
    },
    {
        "codename": "boss attack"
    },
    {
        "codename": "breaking weapon"
    },
    {
        "codename": "bubble wave"
    },
    {
        "codename": "Burning field"
    },
    {
        "codename": "Darkness aura"
    },
    {
        "codename": "death mark"
    },
    {
        "codename": "diamond protection"
    },
    {
        "codename": "Dragon ice"
    },
    {
        "codename": "Dragron of fire"
    },
    {
        "codename": "Elecltrik big aura"
    },
    {
        "codename": "Elektric shock"
    },
    {
        "codename": "elektrik circle"
    },
    {
        "codename": "Fire aura"
    },
    {
        "codename": "fire ball"
    },
    {
        "codename": "fire door"
    },
    {
        "codename": "fire tornado"
    },
    {
        "codename": "fire wind"
    },
    {
        "codename": "flower wave"
    },
    {
        "codename": "full hp"
    },
    {
        "codename": "gods gate"
    },
    {
        "codename": "golden aura"
    },
    {
        "codename": "golden fury"
    },
    {
        "codename": "golden shield"
    },
    {
        "codename": "golden word"
    },
    {
        "codename": "healing aura"
    },
    {
        "codename": "healing fellows"
    },
    {
        "codename": "health boss"
    },
    {
        "codename": "hit"
    },
    {
        "codename": "Ice aura"
    },
    {
        "codename": "ice ball"
    },
    {
        "codename": "ice block"
    },
    {
        "codename": "ice circle"
    },
    {
        "codename": "ice freeze"
    },
    {
        "codename": "lava cage"
    },
    {
        "codename": "leafs attack"
    },
    {
        "codename": "lightning aura"
    },
    {
        "codename": "lotus"
    },
    {
        "codename": "matrix"
    },
    {
        "codename": "meteor aura"
    },
    {
        "codename": "mother nature"
    },
    {
        "codename": "petale"
    },
    {
        "codename": "philosophical stone"
    },
    {
        "codename": "poison"
    },
    {
        "codename": "poison free"
    },
    {
        "codename": "poison heavy"
    },
    {
        "codename": "purple attack"
    },
    {
        "codename": "purple death"
    },
    {
        "codename": "red rock"
    },
    {
        "codename": "shine aura"
    },
    {
        "codename": "sleep aura"
    },
    {
        "codename": "smoke aura"
    },
    {
        "codename": "snake venom"
    },
    {
        "codename": "soap aura"
    },
    {
        "codename": "Star aura"
    },
    {
        "codename": "sun rising"
    },
    {
        "codename": "triple dragon"
    },
    {
        "codename": "ultimate fire tornado"
    },
    {
        "codename": "ultimate golden dragon"
    },
    {
        "codename": "upgrade"
    },
    {
        "codename": "utimate ice dragon"
    },
    {
        "codename": "water aura"
    },
    {
        "codename": "water chaos"
    },
    {
        "codename": "water drop"
    },
    {
        "codename": "water tornado"
    },
    {
        "codename": "white light"
    },
    {
        "codename": "white star aura"
    },
    {
        "codename": "wind of pollen"
    },
    {
        "codename": "windy"
    }
]

export default results;
