let result = [
    {
        "Type": "inc-speed-support",
        "Description": "- \"Increase speed support attack\"=> DPS"
    },
    {
        "Type": "inc-hp-support",
        "Description": "- \"Increase support hp\"=> HP"
    },
    {
        "Type": "inc-gold-drop",
        "Description": "- \"Drop gold\"=> Gold"
    },
    {
        "Type": "freeze-enemy",
        "Description": "- \"Freeze enemy\"=> Freeze Enemy"
    },
    {
        "Type": "poison-enemy",
        "Description": "- \"Poison enemy\"=> Poison Enemy"
    },
    {
        "Type": "sheep-enemy",
        "Description": "- \"Sheep enemy\"=> Sheep Enemy"
    },
    {
        "Type": "fire-enemy",
        "Description": "- \"Increase damage for attack enemy\"=> Damage"
    },
    {
        "Type": "inc_mana",
        "Description": "- \"Increase the total mana available\"=> Mana"
    },
    {
        "Type": "inc_mana_refill_speed",
        "Description": "- \"Increase speed regeneration of Mana\"=> Mana"
    },
    {
        "Type": "inc_size_hero",
        "Description": "- \"Increase size of hero and increase strength\"=> Hero Scale"
    },
    {
        "Type": "auto_heal",
        "Description": "- \"Heal each time receiving a hit\"=> Healing HP"
    },
    {
        "Type": "attack_poison",
        "Description": "- \"Poison enemy when hitting\"=> Poison Enemy"
    },
    {
        "Type": "attack_freeze",
        "Description": "- \"Freeze enemy when hitting\"=> Freeze Enemy"
    },
    {
        "Type": "inc_critical_hit_ratio",
        "Description": "- \"Increase rate critical hit\"=> Critical hit"
    },
    {
        "Type": "inc_gold_enemy_drop",
        "Description": "- \"Increase gold drop from enemy\"=> Gold"
    },
    {
        "Type": "inc_pet_active_time",
        "Description": "- \"Increase pet active time\"=> Pet Active"
    },
    {
        "Type": "inc_healing_on_battle",
        "Description": "- \"Heal support or hero battle\"=> Healing Hp"
    },
    {
        "Type": "revive_hero",
        "Description": "- \"Revive a hero during a battle\"=> Revive"
    },
    {
        "Type": "revive_support",
        "Description": "- \"Revive a support during a battle\"=> Revive"
    },
    {
        "Type": "cure_freeze_poison_sheep",
        "Description": "- \"Cancel Freeze/Poison/Sheep\"=> Pure Freeze/Poison/Sheep depend on Impact.valueType FREEZE / POISON / SHEEP"
    },
    {
        "Type": "inc_powerup_capacity",
        "Description": "- \"Increase the capacity of power up\"=> Power UP capacity"
    },
    {
        "Type": "inc_hp",
        "Description": "- \"Increase the total HP \"=> HP"
    },
    {
        "Type": "fill_hp",
        "Description": "- \"Refill HP battle only\"=> HP"
    },
    {
        "Type": "fill_mana",
        "Description": "- \"Refill Mana\"=> Mana"
    },
    {
        "Type": "inc_hp_refill_speed",
        "Description": "- \"Increase speed regeneration of HP\"=> HP"
    },
    {
        "Type": "inc_dps",
        "Description": "- \"Increase the damage of the global DPS\"=> DPS"
    },
    {
        "Type": "inc_bag_slot",
        "Description": "- \"Increase Bag Slot\""
    },
    {
        "Type": "auto_tap",
        "Description": "- \"Auto Tap\" => auto tap to attack on ghost mode"
    },
    {
        "Type": "holding_tap",
        "Description": "- \"Holding Tap\" => power of holding"
    }
]

export default result;
